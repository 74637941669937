import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Aj Policies" />
    <h1>Policies</h1>
    <h2>Privacy Policy</h2>
    <p>
      Protecting your private information is our priority. This Statement of
      Privacy applies to https://www.articlejobber.com and Aj and governs data
      collection and usage. For the purposes of this Privacy Policy, unless
      otherwise noted, all references to Aj include
      https://www.articlejobber.com and Aj. The Aj website is a tutorial and
      information site. By using the Aj website, you consent to the data
      practices described in this statement.
    </p>

    <h4>Collection of your Personal Information</h4>
    <p>
      We do not collect any personal information about you unless you
      voluntarily provide it to us. However, you may be required to provide
      certain personal information to us when you elect to use certain products
      or services available on the Site. These may include: (a) registering for
      an account on our Site; (b) signing up for special offers from selected
      third parties; (c) sending us an email message; To wit, we will use your
      information for, but not limited to, communicating with you in relation to
      services and/or products you have requested from us. We also may gather
      additional personal or non-personal information in the future.
    </p>

    <h4>Sharing Information with Third Parties</h4>
    <p>
      Aj does not maintain any customer lists and does not sell, rent or lease
      its customer information to third parties.
    </p>

    <h4>Tracking User Behavior</h4>
    <p>
      Aj may keep track of the websites and pages our users visit within Aj, in
      order to determine what Aj pages are the most popular. This data is used
      to deliver customized content and advertising within Aj to customers whose
      behavior indicates that they are interested in a particular subject area.
    </p>

    <h4>Automatically Collected Information</h4>
    <p>
      Information about your computer hardware and software may be automatically
      collected by Aj. This information can include: your IP address, browser
      type, domain names, access times and referring website addresses. This
      information is used for the operation of the service, to maintain quality
      of the service, and to provide general statistics regarding use of the Aj
      website.
    </p>

    <h4>Links</h4>
    <p>
      This website contains links to other sites. Please be aware that we are
      not responsible for the content or privacy practices of such other sites.
      We encourage our users to be aware when they leave our site and to read
      the privacy statements of any other site that collects personally
      identifiable information.
    </p>

    <h4>Security of your Personal Information</h4>
    <p>
      Aj secures your personal information from unauthorized access, use, or
      disclosure. Aj uses the following methods for this purpose: SSL Protocol
      When personal information (such as a credit card number) is transmitted to
      other websites, it is protected through the use of encryption, such as the
      Secure Sockets Layer (SSL) protocol. We strive to take appropriate
      security measures to protect against unauthorized access to or alteration
      of your personal information. Unfortunately, no data transmission over the
      Internet or any wireless network can be guaranteed to be 100% secure. As a
      result, while we strive to protect your personal information, you
      acknowledge that: (a) there are security and privacy limitations inherent
      to the Internet which are beyond our control; and (b) security, integrity,
      and privacy of any and all information and data exchanged between you and
      us through this Site cannot be guaranteed.
    </p>

    <h4>Children Under Thirteen</h4>
    <p>
      Aj does not knowingly collect personally identifiable information from
      children under the age of thirteen. If you are under the age of thirteen,
      you must ask your parent or guardian for permission to use this website.
    </p>

    <h4>Changes to this Statement</h4>
    <p>
      Aj reserves the right to change this Privacy Policy from time to time. We
      will notify you about significant changes in the way we treat personal
      information by sending a notice to the primary email address specified in
      your account, by placing a prominent notice on our site, and/or by
      updating any privacy information on this page. Your continued use of the
      Site and/or Services available through this Site after such modifications
      will constitute your: (a) acknowledgment of the modified Privacy Policy;
      and (b) agreement to abide and be bound by that Policy.
    </p>

    <h4>Contact Information</h4>
    <p>
      Aj welcomes your questioms or comments regarding this Statement of
      Privacy. If you believe that Aj has not adhered to this Statement, please
      contact Aj at: <em>info@articlejobber.com</em>.
    </p>
  </Layout>
)
